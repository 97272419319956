export const FirebaseConfig = {
	"projectId": "instacab-ba18a",
	"appId": "1:747260142341:web:8a440f4dfa0824af438d6c",
	"databaseURL": "https://instacab-ba18a-default-rtdb.firebaseio.com",
	"storageBucket": "instacab-ba18a.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyDMRAxUvQO05H_4WTAxtt06oW3E59EPAVo",
	"authDomain": "instacab-ba18a.firebaseapp.com",
	"messagingSenderId": "747260142341",
	"measurementId": "G-6CEW8J6PGK"
};